import React from 'react'

export function PiZipAtachmentIcon() {
  return (
    <svg
      width='30'
      height='40'
      viewBox='0 0 30 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.4568 13.0577C19.6189 12.9953 19.7448 12.897 19.8361 12.7625C19.9272 12.6294 19.973 12.4733 19.973 12.2957C19.973 12.076 19.9082 11.896 19.7809 11.7546C19.6526 11.6118 19.4892 11.5229 19.2926 11.487C19.1485 11.4605 18.8571 11.4463 18.4192 11.4463H17.832V13.1528H18.4969C18.9755 13.1526 19.2958 13.1215 19.4569 13.0578L19.4568 13.0577Z'
        fill='#8E99B2'
      />
      <path
        d='M27.6 0.799805H2.4C1.08 0.799805 0 1.8798 0 3.1998V36.7998C0 38.1198 1.08 39.1998 2.4 39.1998H27.6C28.92 39.1998 30 38.1198 30 36.7998V3.1998C30 1.8798 28.92 0.799805 27.6 0.799805ZM16.6176 10.4286H18.5665C19.3045 10.4286 19.7858 10.4586 20.01 10.5186C20.3545 10.6086 20.6424 10.8041 20.8752 11.1065C21.108 11.409 21.2245 11.799 21.2245 12.279C21.2245 12.6473 21.1573 12.9569 21.0228 13.209C20.8897 13.461 20.7193 13.6579 20.5128 13.8017C20.3052 13.9445 20.0952 14.0393 19.8828 14.0862C19.5935 14.1438 19.1735 14.1714 18.6239 14.1714H17.8318V16.4407H16.6174L16.6176 10.4286ZM14.2476 10.4286H15.4621V16.4417H14.2476V10.4286ZM8.6328 15.3472L11.7911 11.4461H8.9904V10.4285H13.3908V11.3717L10.0967 15.4289H13.5178V16.4417H8.63259L8.6328 15.3472ZM23.9914 31.4492H6.00789V30.2492H23.9907L23.9914 31.4492ZM23.9914 27.1999H6.00789V25.9999H23.9907L23.9914 27.1999Z'
        fill='#8E99B2'
      />
    </svg>
  )
}
