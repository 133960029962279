import React from 'react'

export function PiMailIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.40332 7.0606L11.9997 11.8582L21.5961 7.0606C21.5606 6.44914 21.2926 5.87439 20.8471 5.45408C20.4016 5.03376 19.8122 4.79969 19.1997 4.7998H4.79972C4.18722 4.79969 3.59786 5.03376 3.15233 5.45408C2.70681 5.87439 2.43885 6.44914 2.40332 7.0606V7.0606Z" fill="#B6C1D6"/>
      <path d="M21.6004 9.74121L12.0004 14.5412L2.40039 9.74121V16.7996C2.40039 17.4361 2.65325 18.0466 3.10333 18.4967C3.55342 18.9468 4.16387 19.1996 4.80039 19.1996H19.2004C19.8369 19.1996 20.4474 18.9468 20.8974 18.4967C21.3475 18.0466 21.6004 17.4361 21.6004 16.7996V9.74121Z" fill="#B6C1D6"/>
      </svg>


  )
}