import React from 'react'

export function PiCopyIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.2 9.60354H13.8C13.3226 9.60354 12.8648 9.4139 12.5272 9.07633C12.1897 8.73877 12 8.28093 12 7.80354V5.40354C11.9993 5.28436 12.0341 5.16768 12.1 5.06837C12.1659 4.96906 12.2599 4.89162 12.3699 4.84592C12.48 4.80022 12.6012 4.78833 12.718 4.81177C12.8349 4.83521 12.9421 4.89292 13.026 4.97754L16.626 8.57754C16.7106 8.66146 16.7683 8.76868 16.7918 8.88553C16.8152 9.00238 16.8033 9.12356 16.7576 9.23363C16.7119 9.34369 16.6345 9.43766 16.5352 9.50354C16.4359 9.56942 16.3192 9.60423 16.2 9.60354ZM13.2 6.84954V7.80354C13.2 7.96267 13.2632 8.11528 13.3757 8.2278C13.4883 8.34032 13.6409 8.40354 13.8 8.40354H14.754L13.2 6.84954Z" fill="#124eb0"/>
    <path d="M14.9996 21.6035H5.39961C4.92222 21.6035 4.46438 21.4138 4.12682 21.0763C3.78925 20.7387 3.59961 20.2809 3.59961 19.8035V6.60348C3.59961 6.12609 3.78925 5.66825 4.12682 5.33068C4.46438 4.99312 4.92222 4.80348 5.39961 4.80348H12.5996C12.6786 4.80302 12.7569 4.81816 12.83 4.84801C12.9031 4.87787 12.9695 4.92187 13.0256 4.97748L16.6256 8.57748C16.6812 8.63354 16.7252 8.70003 16.7551 8.77313C16.7849 8.84624 16.8001 8.92451 16.7996 9.00348V19.8035C16.7996 20.2809 16.61 20.7387 16.2724 21.0763C15.9348 21.4138 15.477 21.6035 14.9996 21.6035ZM5.39961 6.00348C5.24048 6.00348 5.08787 6.06669 4.97535 6.17921C4.86282 6.29173 4.79961 6.44435 4.79961 6.60348V19.8035C4.79961 19.9626 4.86282 20.1152 4.97535 20.2277C5.08787 20.3403 5.24048 20.4035 5.39961 20.4035H14.9996C15.1587 20.4035 15.3114 20.3403 15.4239 20.2277C15.5364 20.1152 15.5996 19.9626 15.5996 19.8035V9.24948L12.3536 6.00348H5.39961Z" fill="#124eb0"/>
    <path d="M19.7996 7.20339H17.3996C16.9222 7.20339 16.4644 7.01375 16.1268 6.67618C15.7893 6.33862 15.5996 5.88078 15.5996 5.40339V3.00339C15.5989 2.88422 15.6337 2.76754 15.6996 2.66822C15.7655 2.56891 15.8595 2.49147 15.9695 2.44577C16.0796 2.40007 16.2008 2.38818 16.3176 2.41162C16.4345 2.43507 16.5417 2.49277 16.6256 2.57739L20.2256 6.17739C20.3102 6.26131 20.3679 6.36853 20.3914 6.48538C20.4148 6.60223 20.4029 6.72341 20.3572 6.83348C20.3115 6.94355 20.2341 7.03751 20.1348 7.10339C20.0355 7.16927 19.9188 7.20408 19.7996 7.20339ZM16.7996 4.44939V5.40339C16.7996 5.56252 16.8628 5.71513 16.9754 5.82766C17.0879 5.94018 17.2405 6.00339 17.3996 6.00339H18.3536L16.7996 4.44939Z" fill="#124eb0"/>
    <path d="M18.6002 19.2036H16.2002C16.0411 19.2036 15.8885 19.1404 15.7759 19.0278C15.6634 18.9153 15.6002 18.7627 15.6002 18.6036C15.6002 18.4444 15.6634 18.2918 15.7759 18.1793C15.8885 18.0668 16.0411 18.0036 16.2002 18.0036H18.6002C18.7593 18.0036 18.9119 17.9404 19.0245 17.8278C19.137 17.7153 19.2002 17.5627 19.2002 17.4036V6.84957L15.9542 3.60357H9.0002C8.84107 3.60357 8.68845 3.66679 8.57593 3.77931C8.46341 3.89183 8.4002 4.04444 8.4002 4.20357V5.40357C8.4002 5.5627 8.33698 5.71532 8.22446 5.82784C8.11194 5.94036 7.95933 6.00357 7.8002 6.00357C7.64107 6.00357 7.48845 5.94036 7.37593 5.82784C7.26341 5.71532 7.2002 5.5627 7.2002 5.40357V4.20357C7.2002 3.72618 7.38984 3.26835 7.7274 2.93078C8.06497 2.59322 8.52281 2.40357 9.0002 2.40357H16.2002C16.2792 2.40312 16.3574 2.41825 16.4305 2.44811C16.5036 2.47797 16.5701 2.52197 16.6262 2.57757L20.2262 6.17757C20.2818 6.23364 20.3258 6.30013 20.3557 6.37323C20.3855 6.44633 20.4007 6.52461 20.4002 6.60357V17.4036C20.4002 17.881 20.2106 18.3388 19.873 18.6764C19.5354 19.0139 19.0776 19.2036 18.6002 19.2036Z" fill="#124eb0"/>
    <path d="M13.8 12.0035H6.6C6.44087 12.0035 6.28826 11.9403 6.17574 11.8277C6.06321 11.7152 6 11.5626 6 11.4035C6 11.2443 6.06321 11.0917 6.17574 10.9792C6.28826 10.8667 6.44087 10.8035 6.6 10.8035H13.8C13.9591 10.8035 14.1117 10.8667 14.2243 10.9792C14.3368 11.0917 14.4 11.2443 14.4 11.4035C14.4 11.5626 14.3368 11.7152 14.2243 11.8277C14.1117 11.9403 13.9591 12.0035 13.8 12.0035Z" fill="#124eb0"/>
    <path d="M13.8 14.4036H6.6C6.44087 14.4036 6.28826 14.3404 6.17574 14.2279C6.06321 14.1154 6 13.9627 6 13.8036C6 13.6445 6.06321 13.4919 6.17574 13.3793C6.28826 13.2668 6.44087 13.2036 6.6 13.2036H13.8C13.9591 13.2036 14.1117 13.2668 14.2243 13.3793C14.3368 13.4919 14.4 13.6445 14.4 13.8036C14.4 13.9627 14.3368 14.1154 14.2243 14.2279C14.1117 14.3404 13.9591 14.4036 13.8 14.4036Z" fill="#124eb0"/>
    <path d="M10.2 16.8035H6.6C6.44087 16.8035 6.28826 16.7403 6.17574 16.6278C6.06321 16.5153 6 16.3626 6 16.2035C6 16.0444 6.06321 15.8918 6.17574 15.7793C6.28826 15.6667 6.44087 15.6035 6.6 15.6035H10.2C10.3591 15.6035 10.5117 15.6667 10.6243 15.7793C10.7368 15.8918 10.8 16.0444 10.8 16.2035C10.8 16.3626 10.7368 16.5153 10.6243 16.6278C10.5117 16.7403 10.3591 16.8035 10.2 16.8035Z" fill="#124eb0"/>
    </svg>
  )
}