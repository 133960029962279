import React from 'react'

export function PiPptAtachmentIcon() {
  return (
    <svg
      width='30'
      height='40'
      viewBox='0 0 30 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.2798 13.0206C10.4419 12.9582 10.5678 12.8599 10.6591 12.7254C10.7502 12.5923 10.796 12.4361 10.796 12.2586C10.796 12.0389 10.7312 11.8589 10.6039 11.7175C10.4756 11.5747 10.3122 11.4858 10.1156 11.4499C9.9715 11.4234 9.68008 11.4092 9.24215 11.4092H8.65527V13.1157H9.32009C9.79983 13.1155 10.1202 13.0844 10.2798 13.0206L10.2798 13.0206Z'
        fill='#8E99B2'
      />
      <path
        d='M15.8833 13.0206C16.0454 12.9582 16.1713 12.8599 16.2626 12.7254C16.3537 12.5923 16.3995 12.4361 16.3995 12.2586C16.3995 12.0389 16.3347 11.8589 16.2074 11.7175C16.0791 11.5747 15.9157 11.4858 15.7191 11.4499C15.575 11.4234 15.2836 11.4092 14.8457 11.4092H14.2588V13.1157H14.9236C15.4033 13.1155 15.7237 13.0844 15.8833 13.0206L15.8833 13.0206Z'
        fill='#8E99B2'
      />
      <path
        d='M27.6 0.799805H2.4C1.08 0.799805 0 1.8798 0 3.1998V36.7998C0 38.1198 1.08 39.1998 2.4 39.1998H27.6C28.92 39.1998 30 38.1198 30 36.7998V3.1998C30 1.8798 28.92 0.799805 27.6 0.799805ZM18.2181 10.3916H22.9964V11.4092H21.2157V16.4047H20.0012L20.0015 11.4092H18.2183L18.2181 10.3916ZM13.045 10.3916H14.9939C15.7319 10.3916 16.2132 10.4216 16.4374 10.4816C16.7819 10.5716 17.0698 10.7671 17.3026 11.0695C17.5353 11.3719 17.6518 11.762 17.6518 12.242C17.6518 12.6103 17.5846 12.9199 17.4502 13.1719C17.317 13.424 17.1467 13.6209 16.9402 13.7647C16.7326 13.9075 16.5226 14.0023 16.3102 14.0491C16.0209 14.1067 15.6009 14.1343 15.0513 14.1343H14.2592V16.4036H13.0447L13.045 10.3916ZM7.44219 10.3916H9.39113C10.1291 10.3916 10.6104 10.4216 10.8346 10.4816C11.1791 10.5716 11.467 10.7671 11.6998 11.0695C11.9325 11.3719 12.0491 11.762 12.0491 12.242C12.0491 12.6103 11.9819 12.9199 11.8474 13.1719C11.7143 13.424 11.5439 13.6209 11.3374 13.7647C11.1298 13.9075 10.9198 14.0023 10.7074 14.0491C10.4181 14.1067 9.99809 14.1343 9.44848 14.1343H8.65642V16.4036L7.44222 16.4034L7.44219 10.3916ZM23.9912 31.4492H6.00768V30.2492H23.9905L23.9912 31.4492ZM23.9912 27.1999H6.00768V25.9999H23.9905L23.9912 27.1999Z'
        fill='#8E99B2'
      />
    </svg>
  )
}
