import React from 'react'

export function PiCloseIcon({ handleClose }: any) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={handleClose}
    >
      <rect width='16' height='16' fill='white' />
      <path
        d='M8.00003 7.05733L4.47136 3.52867C4.34563 3.40723 4.17722 3.34003 4.00243 3.34155C3.82763 3.34307 3.66042 3.41318 3.53682 3.53679C3.41321 3.66039 3.3431 3.8276 3.34158 4.0024C3.34006 4.1772 3.40726 4.3456 3.52869 4.47133L7.05736 8L3.52869 11.5287C3.40726 11.6544 3.34006 11.8228 3.34158 11.9976C3.3431 12.1724 3.41321 12.3396 3.53682 12.4632C3.66042 12.5868 3.82763 12.6569 4.00243 12.6584C4.17722 12.66 4.34563 12.5928 4.47136 12.4713L8.00003 8.94267L11.5287 12.4713C11.6544 12.5928 11.8228 12.66 11.9976 12.6584C12.1724 12.6569 12.3396 12.5868 12.4632 12.4632C12.5868 12.3396 12.657 12.1724 12.6585 11.9976C12.66 11.8228 12.5928 11.6544 12.4714 11.5287L8.94269 8L12.4714 4.47133C12.535 4.40984 12.5858 4.33627 12.6208 4.25494C12.6557 4.1736 12.6741 4.08612 12.6749 3.9976C12.6756 3.90908 12.6588 3.82129 12.6252 3.73936C12.5917 3.65743 12.5422 3.583 12.4796 3.5204C12.417 3.45781 12.3426 3.40831 12.2607 3.37479C12.1787 3.34126 12.0909 3.3244 12.0024 3.32517C11.9139 3.32594 11.8264 3.34433 11.7451 3.37927C11.6638 3.4142 11.5902 3.46499 11.5287 3.52867L8.00003 7.05733Z'
        fill='#8E99B2'
      />
    </svg>
  )
}
