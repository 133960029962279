import React from 'react'

export function PiZoomIcon() {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.3697 16.0114L14.1162 12.7578C15.3117 11.2098 15.8731 9.26365 15.6859 7.31548C15.4987 5.36738 14.5772 3.56392 13.1088 2.27255C11.6404 0.981189 9.73561 0.298919 7.78273 0.364702C5.82962 0.430482 3.97513 1.2395 2.59646 2.62673C1.21778 4.01396 0.419585 5.8753 0.363502 7.83154C0.307565 9.78779 0.998296 11.6919 2.29502 13.1562C3.59171 14.6203 5.39708 15.5342 7.34323 15.712C9.2894 15.8897 11.2301 15.3179 12.7698 14.1129L16.0185 17.3712L16.0187 17.3713C16.2617 17.6064 16.6105 17.6957 16.9365 17.6062C17.2624 17.5167 17.5169 17.2617 17.6063 16.9353C17.6956 16.609 17.6065 16.2596 17.3717 16.0162L17.3697 16.0114ZM2.33894 8.06398C2.33894 6.53902 2.94383 5.07638 4.02066 3.99822C5.09735 2.91981 6.55785 2.31404 8.08048 2.31404C9.60311 2.31404 11.0637 2.91981 12.1403 3.99822C13.2171 5.07649 13.822 6.53912 13.822 8.06398C13.822 9.58883 13.2171 11.0516 12.1403 12.1297C11.0636 13.2081 9.60311 13.8139 8.08048 13.8139C6.55816 13.8124 5.09858 13.2061 4.02202 12.1281C2.94547 11.0501 2.34017 9.58842 2.3387 8.06374L2.33894 8.06398Z'
        fill='white'
      />
      <path
        d='M10.9515 7.10549H9.03763V5.18881C9.03763 4.84641 8.85527 4.53009 8.55916 4.35882C8.26305 4.18769 7.89833 4.18769 7.60222 4.35882C7.30611 4.53008 7.12375 4.84641 7.12375 5.18881V7.10549H5.20987C4.86797 7.10549 4.55211 7.28812 4.38109 7.58466C4.21021 7.8812 4.21021 8.24646 4.38109 8.543C4.55211 8.83954 4.86797 9.02217 5.20987 9.02217H7.12375V10.9389C7.12375 11.2813 7.30611 11.5976 7.60222 11.7688C7.89833 11.94 8.26306 11.94 8.55916 11.7688C8.85527 11.5976 9.03763 11.2813 9.03763 10.9389V9.02217H10.9515C11.2934 9.02217 11.6093 8.83954 11.7803 8.543C11.9512 8.24646 11.9512 7.8812 11.7803 7.58466C11.6093 7.28812 11.2934 7.10549 10.9515 7.10549Z'
        fill='white'
      />
    </svg>
  )
}
