import React from 'react'

export function PiEditIcon({ onClick }: any) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={() => onClick(true)}
    >
      <path
        d='M8.86919 0.871274C9.01679 0.718457 9.19334 0.596566 9.38854 0.512711C9.58375 0.428857 9.7937 0.384719 10.0061 0.382873C10.2186 0.381027 10.4293 0.42151 10.6259 0.501959C10.8226 0.582408 11.0012 0.701213 11.1514 0.851442C11.3017 1.00167 11.4205 1.18031 11.5009 1.37695C11.5814 1.57358 11.6218 1.78427 11.62 1.99672C11.6181 2.20916 11.574 2.41911 11.4902 2.61432C11.4063 2.80953 11.2844 2.98608 11.1316 3.13367L10.4972 3.76807L8.23479 1.50567L8.86919 0.871274ZM7.10359 2.63687L0.400391 9.34007V11.6025H2.66279L9.36679 4.89927L7.10279 2.63687H7.10359Z'
        fill='#134C85'
      />
    </svg>
  )
}
