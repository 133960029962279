import React from 'react'

export function PiAttachmentDownloadIcon() {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.75 9.75H13.5C14.7721 9.56804 15.75 8.44743 15.75 7.125C15.75 5.67525 14.5747 4.5 13.125 4.5C12.8998 4.5 12.6811 4.52837 12.4725 4.58173C11.9132 3.21381 10.5692 2.25 9 2.25C7.13463 2.25 5.58737 3.61199 5.29842 5.39578C5.05029 5.30157 4.78117 5.25 4.5 5.25C3.25736 5.25 2.25 6.25736 2.25 7.5C2.25 8.74264 3.25736 9.75 4.5 9.75H5.25C5.87132 9.75 6.375 10.2537 6.375 10.875C6.375 11.4963 5.87132 12 5.25 12H4.5C2.01472 12 0 9.98528 0 7.5C0 5.26378 1.63115 3.40853 3.76856 3.05915C4.80346 1.22065 6.77285 0 9 0C10.8804 0 12.5989 0.873288 13.7125 2.28504C16.1279 2.57515 18 4.63144 18 7.125C18 9.55855 16.2075 11.6356 13.8186 11.9773L13.6634 11.9995L12.7813 11.9996C12.7709 11.9999 12.7605 12 12.75 12C12.1287 12 11.625 11.4963 11.625 10.875C11.625 10.2537 12.1287 9.75 12.75 9.75ZM10.125 14.159L10.4545 13.8295C10.8938 13.3902 11.6062 13.3902 12.0455 13.8295C12.4848 14.2688 12.4848 14.9812 12.0455 15.4205L9.7955 17.6705C9.35616 18.1098 8.64384 18.1098 8.2045 17.6705L5.9545 15.4205C5.51517 14.9812 5.51517 14.2688 5.9545 13.8295C6.39384 13.3902 7.10616 13.3902 7.5455 13.8295L7.875 14.159V5.25C7.875 4.62868 8.37868 4.125 9 4.125C9.62132 4.125 10.125 4.62868 10.125 5.25V14.159Z'
        fill='white'
      />
    </svg>
  )
}
