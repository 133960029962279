import React from 'react'

export function PiPdfAtachmentIcon() {
  return (
    <svg
      width='30'
      height='39'
      viewBox='0 0 30 39'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M27.6 0H2.4C1.08 0 0 1.08 0 2.4V36C0 37.32 1.08 38.4 2.4 38.4H27.6C28.92 38.4 30 37.32 30 36V2.4C30 1.08 28.92 0 27.6 0ZM18.888 9.58423H23.0111V10.6018H20.1022V12.0263H22.6125V13.0439H20.1022V15.5987H18.888V9.58423ZM12.81 9.58423H15.0289C15.5293 9.58423 15.911 9.62253 16.1737 9.69941C16.5265 9.80387 16.8289 9.98735 17.0809 10.2539C17.3316 10.519 17.5237 10.8432 17.6558 11.2283C17.7878 11.6135 17.8526 12.0863 17.8526 12.6504C17.8526 13.1448 17.7902 13.572 17.6678 13.9296C17.5178 14.3665 17.303 14.7203 17.0247 14.9917C16.8136 15.1969 16.5291 15.3576 16.1716 15.4717C15.904 15.5558 15.5451 15.5976 15.0964 15.5976H12.8116L12.8111 9.5842L12.81 9.58423ZM7.21077 9.58423H9.1597C9.89767 9.58423 10.379 9.61423 10.6032 9.67423C10.9477 9.76423 11.2356 9.95977 11.4684 10.2622C11.7011 10.5646 11.8177 10.9546 11.8177 11.4346C11.8177 11.8029 11.7504 12.1126 11.6173 12.3646C11.4842 12.6166 11.3138 12.8135 11.1073 12.9573C10.9008 13.1001 10.6908 13.1949 10.4773 13.2418C10.1869 13.2994 9.76799 13.327 9.21839 13.327H8.42632V15.5963H7.21076L7.21077 9.58423ZM23.9916 30.6494H6.00802V29.4494H23.9909L23.9916 30.6494ZM23.9916 26.4001H6.00802V25.2001H23.9909L23.9916 26.4001Z'
        fill='#8E99B2'
      />
    </svg>
  )
}
