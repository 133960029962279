import React from 'react'

export function PiHtmlAtachmentIcon() {
  return (
    <svg
      width='30'
      height='40'
      viewBox='0 0 30 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M27.6 0.799805H2.4C1.08 0.799805 0 1.8798 0 3.1998V36.7998C0 38.1198 1.08 39.1998 2.4 39.1998H27.6C28.92 39.1998 30 38.1198 30 36.7998V3.1998C30 1.8798 28.92 0.799805 27.6 0.799805ZM15.205 10.384H17.023L18.1137 14.4857L19.1926 10.384H21.0143V16.3971H19.8863V11.6643L18.6922 16.3971H17.5233L16.334 11.6643V16.3971H15.2061L15.2063 10.384H15.205ZM9.65986 10.384H14.4382V11.4016H12.6586V16.3972H11.4441L11.4444 11.4016H9.65992L9.65986 10.384ZM5.24263 16.3971H4.02816V10.384H5.24263V12.7506H7.62096V10.384H8.83543V16.3971H7.62096V13.7678H5.24263V16.3971ZM23.9914 31.4486H6.00789V30.2486H23.9907L23.9914 31.4486ZM23.9914 27.1993H6.00789V25.9993H23.9907L23.9914 27.1993ZM26.4849 16.3965H22.2525V10.4324H23.4669V15.3834H26.4862V16.3964L26.4849 16.3965Z'
        fill='#8E99B2'
      />
    </svg>
  )
}
