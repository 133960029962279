import React from 'react'

export function PiDeleteIcon() {
  return (
    <svg
      width='16'
      height='18'
      viewBox='0 0 16 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.75 15.75C2.75 16.1642 3.08579 16.5 3.5 16.5H12.5C12.9142 16.5 13.25 16.1642 13.25 15.75V6.75H2.75V15.75ZM11.75 2.25H13.25C14.4926 2.25 15.5 3.25736 15.5 4.5V6C15.5 6.41421 15.1642 6.75 14.75 6.75V15.75C14.75 16.9926 13.7426 18 12.5 18H3.5C2.25736 18 1.25 16.9926 1.25 15.75V6.75C0.835786 6.75 0.5 6.41421 0.5 6V4.5C0.5 3.25736 1.50736 2.25 2.75 2.25H4.25C4.25 1.00736 5.25736 0 6.5 0H9.5C10.7426 0 11.75 1.00736 11.75 2.25ZM4.25 9.75C4.25 9.33579 4.58579 9 5 9C5.41421 9 5.75 9.33579 5.75 9.75V13.5C5.75 13.9142 5.41421 14.25 5 14.25C4.58579 14.25 4.25 13.9142 4.25 13.5V9.75ZM7.25 9.75C7.25 9.33579 7.58579 9 8 9C8.41421 9 8.75 9.33579 8.75 9.75V13.5C8.75 13.9142 8.41421 14.25 8 14.25C7.58579 14.25 7.25 13.9142 7.25 13.5V9.75ZM10.25 9.75C10.25 9.33579 10.5858 9 11 9C11.4142 9 11.75 9.33579 11.75 9.75V13.5C11.75 13.9142 11.4142 14.25 11 14.25C10.5858 14.25 10.25 13.9142 10.25 13.5V9.75ZM6.5 1.5C6.08579 1.5 5.75 1.83579 5.75 2.25H10.25C10.25 1.83579 9.91421 1.5 9.5 1.5H6.5ZM2 5.25H14V4.5C14 4.08579 13.6642 3.75 13.25 3.75H2.75C2.33579 3.75 2 4.08579 2 4.5V5.25Z'
        fill='#fff'
      />
    </svg>
  )
}
